
.swiper-button-next {
    color: #ffe600; 
  }
  
  .swiper-button-prev {
    color: #ffe600;
  }

.swiper-slide {
    width: auto !important; /* Make slide width depend on the image width */
}


  