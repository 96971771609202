

  .swiper-button-next
  .swiper-button-prev {
    color: #ffe600;
  }
  
  .swiper-slidewide {
    width: 100% !important;
}

.swiper-pagination-bullets {
  display: none;
}

  